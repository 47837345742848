import React, { Component } from 'react'
import { Carousel, Toast, Modal } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';
import { req_findSlideshow, req_findModuleByRoleId, req_findBxOrder, req_updateBxOrder } from '../../api/index'
import IP from '../../config/config'
import moment from 'moment'
import './index.css'

import img3 from './../../image/03.png'
import img4 from './../../image/04.png'
import img5 from './../../image/05.png'

const alert = Modal.alert;
const operation = Modal.operation;

class index extends Component {

    state = {
        slideData: [], // 轮播图
        gridData: [], // 模块
        searchName: '', // 搜索条件
        orderData: [], // 工单
        visible:false,
    }

    componentWillMount = () => {
        this.findSlideData()
        this.findModuleData()
        this.findBxOrder();
    }

    // 获取轮播图
    findSlideData = async () => {
        const res = await req_findSlideshow({ pageNum: 1, pageSize: 99 })
        if (res.code === 20000) {
            this.setState({
                slideData: res.data.list
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 获取模块信息
    findModuleData = async () => {
        const res = await req_findModuleByRoleId()
        if (res.code === 20000) {
            this.setState({
                gridData: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 查询我的工单
    findBxOrder = async () => {
        const res = await req_findBxOrder()
        if (res.code === 20000) {
            this.setState({
                orderData: res.data.length > 3 ? res.data.slice(0, 3) : res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }

    

    // 取消工单
    cancelOrder=(orderId)=>{
        alert('取消工单', '你确定取消工单吗???', [
            { text: '取消', onPress: () =>{}},
            { text: '确定', onPress: async() => {
                const res = await req_updateBxOrder({orderId,flag:1})
                if(res.code === 20000){
                    this.findBxOrder();
                    Toast.success(res.message)
                }else{
                    Toast.fail(res.message)
                }
            }},
          ]);
    }

    render() {
        const { slideData, gridData, orderData, visible } = this.state
        return (
            <div className='home' >
                <div className="header">
                    {/* <div className="header-logo">
                        <img src={img1} alt="" />
                    </div> */}
                    {/* <div className="header-search">
                        <SearchBar placeholder="搜索维修工单" onChange={(val) => {
                            this.setState({
                                searchName: val
                            })
                        }} />
                    </div> */}
                    {/* <div className="header-qrcode">
                        <img src={img2} alt="" />
                    </div> */}
                </div>
                { /* 轮播图 */}
                <Carousel
                    className="spaceCarousel"
                    cellSpacing={20}
                    slideWidth={0.94}
                    dotActiveStyle={{ background: '#ffffff' }}
                    dotStyle={{ background: 'rgb(255,255,255,0.5)' }}
                    infinite={true}
                    autoplay={true}
                    afterChange={index => this.setState({ slideIndex: index })} >
                    {slideData.map((val, index) =>
                        <img id={`img${val.slideshow_id}`}
                            key={index}
                            src={IP.host + val.slideshow_url}
                            alt=""
                            style={
                                { width: '100%', zIndex: 5, height: '140px', borderRadius: 5 }}
                            onLoad={
                                () => {
                                    this.setState({
                                        imgHeight: 140
                                    })
                                }
                            }
                        />
                    )
                    }
                </Carousel>

                { /* 模块 */}
                <div className="module" >
                    <ul>
                        {
                            gridData.length > 0 ? gridData.map(x => {
                                return (
                                    <li key={x.id}
                                        onClick={
                                            () => {
                                                this.props.history.push(x.module_url==='/my/index'?x.module_url+'/0':x.module_url)
                                            }
                                        } >
                                        <div className="img-size"><img src={IP.host + x.module_image_url} className='moduleIcon' alt="" /></div>
                                        <span> {x.module_name} </span>
                                    </li>
                                )
                            }) : ''
                        }
                    </ul>
                </div>

                <div className="module-nav">
                    <div className="module-nav-item" onClick={() => {
                        // this.props.history.push({ pathname: '/repairs/index' })
                        this.setState({
                            visible:true
                        })
                        // operation([
                        //     { text: '填单报修', onPress: () =>  this.props.history.push({ pathname: '/repairs/single' })},
                        //     { text: '电话报修', onPress: () =>  this.props.history.push({ pathname: '/repairs/phone' })},
                        //   ])
                    }}>
                        <div className="module-text">
                            <div>快速报修</div>
                            <div>多种报修方式</div>
                        </div>
                        <div className="module-icon">
                            <img src={img3} alt="" />
                        </div>
                    </div>
                    {
                        gridData.filter(x=>Number(x.id)===51).length!==0 ? (<div className="module-nav-item"  onClick={() => {
                            this.props.history.push({ pathname: '/record/add' })
                        }}>
                            <div className="module-text">
                                <div>填写日志</div>
                                <div>每日工作日志</div>
                            </div>
                            <div className="module-icon">
                                <img src={img4} alt="" />
                            </div>
                        </div>):(<div className="module-nav-item" style={{background:'none'}}></div>)
                    }
                </div>
                <div className="order-top">
                    <div className="order-new">最新工单</div>
                    <div className="order-more">
                        <div onClick={() => {
                            this.props.history.push('/repairs/list')
                        }}>
                            查看更多
                        </div>
                        <div>
                            <img src={img5} alt="" />
                        </div>
                    </div>
                </div>
                <div className="order-details">
                    {
                        orderData && orderData.map((val, key) => {
                            return (
                                <div className="order-item" key={key} style={{borderBottom:'1px solid #f3f3f3'}} onClick={()=>{
                                    this.props.history.push({pathname:`/repairs/details/${val.record_id}`})
                                    }
                                }>
                                    <div className="order-head">
                                    <div>订单编号：{val.order_no}</div>
                                        {val.order_state===1 && (<div className="take-state-orange">待接单</div>)}
                                        {val.order_state===2 && (<div className="take-state-green">已接单</div>)}
                                        {val.order_state===3 && (<div className="take-state-green">已完成</div>)}
                                        {val.order_state===4 && (<div className="take-state-green">已评价</div>)}
                                        {val.order_state===5 && (<div className="take-state-red">已拒接</div>)}
                                        {val.order_state===6 && (<div className="take-state-red">已取消</div>)}
                                    </div>
                                    <div className="order-info">
                                        <div>报修人：{val.bx_name}</div>
                                        <div>报修方式：
                                            { val.repairs_state===1 && '填单报修' }
                                            { val.repairs_state===2 && '语音报修' }
                                            { val.repairs_state===3 && '电话报修' }
                                            { val.repairs_state===4 && '扫码报修' }
                                        </div>
                                        <div>报修地点：{val.building_name+val.room_name}</div>
                                    </div>
                                    <div className="order-foot">
                                        <div className="order-time">
                                            报修时间：{moment(val.create_time).format('MM月DD日 HH:mm')}
                                        </div>
                                        <div className="order-but">
                                            {val.order_state===1 && <div className="order-take" onClick={(e)=>{
                                                e.stopPropagation();
                                                // 阻止与原生事件的冒泡
                                                e.nativeEvent.stopImmediatePropagation();
                                                this.cancelOrder(val.record_id)
                                            }}>取消</div>}

                                            {val.order_state===3 && <div className="order-take" onClick={(e)=>{
                                                e.stopPropagation();
                                                // 阻止与原生事件的冒泡
                                                e.nativeEvent.stopImmediatePropagation();
                                                this.props.history.push({pathname:`/repairs/evaluate/${val.record_id}`})
                                            }}>评价</div>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <Modal
                popup
          visible={visible}
          onClose={()=>{
            console.log(visible)
            this.setState({
                visible:false
            })
          }}
          afterClose={() => { 
            this.setState({
                visible:false
            })
           }}
           animationType="slide-up"
          className="Modal-slide-up"
        >
            <div className="modal-up">
                <div onClick={()=>{
                    this.props.history.push({ pathname: '/repairs/single' })
                }}>填单报修</div>
                <div onClick={()=>{
                    this.props.history.push({ pathname: '/repairs/phone' })
                }}>电话报修</div>
            </div>
        </Modal>
            </div>)
    }
}
export default index;