import React, { Component } from 'react'
import { Button, List, Toast, Picker, TextareaItem } from 'antd-mobile'
import { req_findRepairAreaById, req_submitPatrolRecord, req_findIsSubmit } from './../../api/index'
import cookie from 'react-cookies';

class repairs extends Component {
    state = {
        pickerData:[
            {
                label:'正常',
                value:1
            },
            {
                label:'异常',
                value:2
            }
        ],
        click: false,
        state:[],
        work_desc:'',
    }

    componentWillMount = async() => {
        await this.findIsSubmit()
        await this.findRepairArea()
    }
    // 判断是否有提交权限
    findIsSubmit=async()=>{
        const res = await req_findIsSubmit({roomId: this.props.match.params.id})
        if (res.code === 20000) {
            // 先判断记录
            if(res.data.length>0){
                this.setState({
                    butShow:false,
                    state:[res.data[0].state],
                    work_desc:res.data[0].work_desc
                })
            }else{
                this.setState({
                    butShow:true,
                })
            }
            
        } else {
            Toast.fail(res.message)
        }
    }


    findRepairArea = async () => {
        const res = await req_findRepairAreaById({ areaId: this.props.match.params.id })
        if (res.code === 20000) {
            this.setState({
                repairAreaData: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }
    // 提交
    submit = async () => {
        const { state, work_desc, repairAreaData } = this.state
        // 验证
        if (state.length === 0 || work_desc === '') {
            Toast.offline("请补全上述信息！！！")
            return false;
        }
        Toast.loading('正在提交中...', 0)
        this.setState({
            click: true
        })
        const params = {
            user_id:cookie.load('data').user_id,
            user_name:cookie.load('data').user_name,
            room_id: repairAreaData.area_id,
            room_name:repairAreaData.area_name,
            state:state.join(','),
            work_desc
        }
        const res = await req_submitPatrolRecord(params)
        if (res.code === 20000) {
            Toast.hide();
            Toast.success(res.message)
            this.setState({
                click: false
            })
            setTimeout(() => {
                window.history.go(-1)
            }, 1000);
        } else {
            Toast.hide();
            this.setState({
                click: false
            })
            Toast.fail(res.message)
        }
    }

    render() {
        const { pickerData, state, work_desc, butShow } = this.state
        return (
            <div style={{ height:'100vh',background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List>
                    <Picker
                        data={pickerData}
                        value={state}
                        disabled={!!!butShow}
                        cols={1}
                        onChange={(val)=>{
                            this.setState({
                                state:val
                            })
                        }}
                    >
                        <List.Item arrow="horizontal"><span style={{ color: "red" }}>* </span>巡检状态</List.Item>
                    </Picker>
                    <List  renderHeader={() => <div><span style={{ color: "red" }}>* </span>巡检描述</div>} className="listsingle">
                        <TextareaItem
                            className="my-text"
                            placeholder=""
                            rows={5}
                            count={100}
                            disabled={!!!butShow}
                            value={work_desc}
                            onChange={(val)=>{
                                this.setState({
                                    work_desc:val
                                })
                            }}
                        />
                    </List>
                </List>
                {
                    butShow && (<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button type="primary" disabled={this.state.click || false} style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} onClick={() => {
                        this.submit()
                    }}>提交</Button>
                </div>)
                }
                
            </div>
        )
    }
}
export default repairs;