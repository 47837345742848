import React, { Component } from 'react'
import { NavBar, Icon, Toast, SegmentedControl, Modal, SearchBar } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';
import { req_updateBxOrder, req_findBxOrder, req_findWorkOrder, req_updateWorkOrder, req_findWorkQuanxian } from '../../api/index'
import moment from 'moment'
import './list.css'

const alert = Modal.alert;
const prompt = Modal.prompt;

class index extends Component {

    state = {
        orderState: '',
        repairsDesc: '',
        userOrderData: [],
        visible: false,
        classType: 0,
        autoState: false,
        tabData: [
            {
                value: '',
                label: '全部'
            },
            {
                value: 1,
                label: '待接单'
            },
            {
                value: 2,
                label: '已接单'
            },
            {
                value: 3,
                label: '已完成'
            },
            {
                value: 4,
                label: '已评价'
            },
            {
                value: 5,
                label: '已拒接'
            },
            {
                value: 6,
                label: '已取消'
            }
        ]
    }

    componentWillMount = () => {
        // 绑定监听事件
        document.addEventListener('keydown', this.onkeydown);
        this.findWorkAuth();
        this.findUserOrder();
    }

    // 查询登录用户是否拥有维修工权限
    findWorkAuth = async () => {
        const res = await req_findWorkQuanxian()
        if (res.code === 20000) {
            this.setState({
                autoState: res.data ? true : false
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 获取用户工单
    findUserOrder = async () => {
        const { repairsDesc, orderState } = this.state;
        const res = await req_findBxOrder({ repairsDesc, orderState })
        if (res.code === 20000) {
            this.setState({
                userOrderData: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 查询维修工工单
    findWorkOrder = async () => {
        const { repairsDesc, orderState } = this.state;
        const res = await req_findWorkOrder({ bxName: repairsDesc, orderState })
        if (res.code === 20000) {
            this.setState({
                userOrderData: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 选择
    tabSub = (state) => {
        this.setState({
            orderState: state,
        }, () => {
            if (this.state.classType === 0) {
                this.findUserOrder()
            } else {
                this.findWorkOrder()
            }
        })
    }

    // 取消工单
    cancelOrder = (orderId) => {
        alert('取消工单', '你确定取消工单吗???', [
            { text: '取消', onPress: () => { } },
            {
                text: '确定', onPress: async () => {
                    const res = await req_updateBxOrder({ orderId, flag: 1 })
                    if (res.code === 20000) {
                        this.findUserOrder();
                        Toast.success(res.message)
                    } else {
                        Toast.fail(res.message)
                    }
                }
            },
        ]);
    }

    // 接单
    acceptOrder = (orderId) => {
        alert('接单', '你确定接单吗???', [
            { text: '取消', onPress: () => { } },
            {
                text: '确定', onPress: async () => {
                    const res = await req_updateWorkOrder({ orderId, flag: 2 })
                    if (res.code === 20000) {
                        this.findWorkOrder();
                        Toast.success(res.message)
                    } else {
                        Toast.fail(res.message)
                    }
                }
            },
        ]);
    }

    // 拒接工单
    rejectOrder = (orderId) => {
        prompt('拒接', '你确定拒接吗???',
            [
                {
                    text: '取消',
                    onPress: value => new Promise((resolve) => {
                        resolve();
                    }),
                },
                {
                    text: '确认',
                    onPress: value => new Promise(async (resolve, reject) => {
                        const res = await req_updateWorkOrder({ orderId, flag: 1, rejectDesc: value })
                        if (res.code === 20000) {
                            resolve();
                            this.findWorkOrder();
                            Toast.success(res.message)
                        } else {
                            Toast.fail(res.message)
                        }
                    }),
                },
            ], 'default', null, ['请输入拒接原因'])
    }

    // 填单报修完成
    completeOrder = (orderId) => {
        alert('完成', '你确定完成吗???', [
            { text: '取消', onPress: () => { } },
            {
                text: '确定', onPress: async () => {
                    const res = await req_updateWorkOrder({ orderId, flag: 3 })
                    if (res.code === 20000) {
                        this.findWorkOrder();
                        Toast.success(res.message)
                    } else {
                        Toast.fail(res.message)
                    }
                }
            },
        ]);
    }

    // 电话报修完成
    completeFormOrder = (orderId) => {
        prompt('完成', '你确定完成吗???',
            [
                {
                    text: '取消',
                    onPress: value => new Promise((resolve) => {
                        resolve();
                    }),
                },
                {
                    text: '确认',
                    onPress: value => new Promise(async (resolve, reject) => {
                        if (!value) {
                            Toast.offline('请补全上述信息！！！')
                            return false;
                        }
                        const res = await req_updateWorkOrder({ orderId, flag: 3, repairsDesc: value })
                        if (res.code === 20000) {
                            resolve();
                            this.findWorkOrder();
                            Toast.success(res.message)
                        } else {
                            Toast.fail(res.message)
                        }
                    }),
                },
            ], 'default', null, ['请输入维修描述'])
    }

    // 回车触发
    onkeydown = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                visible: false
            })
            this.findUserOrder()
        }
    }

    render() {
        const { orderState, userOrderData, visible, repairsDesc, classType, tabData, autoState } = this.state
        return (
            <div className='home'>

                {
                    autoState ? (<NavBar
                        mode="light"
                        rightContent={
                            classType ? [
                                <Icon key="0" type="search" style={{ marginRight: '16px' }} onClick={() => {
                                    this.setState({
                                        visible: true
                                    })
                                }} />,
                            ] : ''}
                    >
                        {
                            autoState ? (<SegmentedControl values={['用户工单', '维修工单']} selectedIndex={classType} onChange={(val) => {
                                if (val.nativeEvent.selectedSegmentIndex === 0) {
                                    this.setState({
                                        repairsDesc: '',
                                    }, () => {
                                        this.findUserOrder()
                                    })

                                } else {
                                    this.setState({
                                        repairsDesc: '',
                                    }, () => {
                                        this.findWorkOrder()
                                    })
                                }
                                this.setState({
                                    classType: val.nativeEvent.selectedSegmentIndex
                                })
                            }} />) : ''
                        }
                    </NavBar>) : (<div style={{height:'6px'}}></div>)
                }


                <div className="tables">
                    <div className="tables-item">
                        {
                            tabData.map((val, key) => {
                                return <div key={key} className={orderState === val.value ? 'tabClick' : ''} onClick={() => {
                                    this.tabSub(val.value)
                                }}>{val.label}{orderState === val.value && '(' + userOrderData.length + ')'}</div>
                            })
                        }
                    </div>
                </div>
                <div className="order-details" style={{ margin: '0px 16px' }}>
                    {
                        userOrderData && userOrderData.map((val, key) => {
                            return (
                                <div style={{ borderBottom: '1px solid #f3f3f3' }} className="order-item" key={key} onClick={() => {
                                    this.props.history.push({ pathname: `/repairs/details/${val.record_id}` })
                                }
                                }>
                                    <div className="order-head">
                                        <div>订单编号：{val.order_no}</div>
                                        {val.order_state === 1 && (<div className="take-state-orange">待接单</div>)}
                                        {val.order_state === 2 && (<div className="take-state-green">已接单</div>)}
                                        {val.order_state === 3 && (<div className="take-state-green">已完成</div>)}
                                        {val.order_state === 4 && (<div className="take-state-green">已评价</div>)}
                                        {val.order_state === 5 && (<div className="take-state-red">已拒接</div>)}
                                        {val.order_state === 6 && (<div className="take-state-red">已取消</div>)}
                                    </div>
                                    <div className="order-info">
                                        <div>报修人：{val.bx_name}</div>
                                        <div>报修方式：
                                            {val.repairs_state === 1 && '填单报修'}
                                            {val.repairs_state === 2 && '语音报修'}
                                            {val.repairs_state === 3 && '电话报修'}
                                            {val.repairs_state === 4 && '扫码报修'}
                                        </div>
                                        <div>报修地点：{val.building_name + val.room_name}</div>
                                    </div>
                                    <div className="order-foot">
                                        <div className="order-time">
                                            报修时间：{moment(val.create_time).format('MM月DD日 HH:mm')}
                                        </div>
                                        <div className="order-but">
                                            {/* 用户操作 */}
                                            {(val.order_state === 1 && classType === 0) && <div className="order-take" onClick={(e) => {
                                                e.stopPropagation();
                                                // 阻止与原生事件的冒泡
                                                e.nativeEvent.stopImmediatePropagation();
                                                this.cancelOrder(val.record_id)
                                            }}>取消</div>}

                                            {(val.order_state === 3 && classType === 0) && <div className="order-take" onClick={(e) => {
                                                e.stopPropagation();
                                                // 阻止与原生事件的冒泡
                                                e.nativeEvent.stopImmediatePropagation();
                                                this.props.history.push({ pathname: `/repairs/evaluate/${val.record_id}` })
                                            }}>评价</div>}


                                            {/* 维修工操作 */}
                                            {(val.order_state === 1 && classType === 1) && <div className="order-take" onClick={(e) => {
                                                e.stopPropagation();
                                                // 阻止与原生事件的冒泡
                                                e.nativeEvent.stopImmediatePropagation();
                                                this.rejectOrder(val.record_id)
                                            }}>拒接</div>}
                                            {(val.order_state === 1 && classType === 1) && <div className="order-take" onClick={(e) => {
                                                e.stopPropagation();
                                                // 阻止与原生事件的冒泡
                                                e.nativeEvent.stopImmediatePropagation();
                                                this.acceptOrder(val.record_id)
                                            }}>接单</div>}

                                            {(val.order_state === 2 && classType === 1) && <div className="order-take" onClick={(e) => {
                                                e.stopPropagation();
                                                // 阻止与原生事件的冒泡
                                                e.nativeEvent.stopImmediatePropagation();
                                                // 填单报修完成
                                                if (val.repairs_state === 1) {
                                                    this.completeOrder(val.record_id)
                                                } else {
                                                    // 电话报修完成
                                                    this.completeFormOrder(val.record_id)
                                                }

                                            }}>完成</div>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    userOrderData.length === 0 && <div style={{ textAlign: 'center', paddingTop: '14px', background: 'none' }}>
                        --暂无数据--
                    </div>
                }

                {
                    userOrderData.length !== 0 && <div style={{ textAlign: 'center', paddingTop: '26px', color: '#a4a9b2', background: 'none' }}>
                        已到底部，没有更多工单啦
                    </div>
                }

                <Modal
                    visible={visible}
                >
                    <div className="list-search">
                        <SearchBar placeholder={classType === 0 ? '搜索维修工单描述' : '搜索报修人名字'} value={repairsDesc} onCancel={() => {
                            this.setState({
                                repairsDesc: '',
                                visible: false
                            }, () => {
                                if (classType === 0) {
                                    this.findUserOrder()
                                } else {
                                    this.findWorkOrder()
                                }
                            })
                        }} onKeyDown={(e) => this.onkeydown(e)} onChange={(val) => {
                            this.setState({
                                repairsDesc: val
                            })
                        }} />
                        <div className="sub-search" onClick={() => {
                            this.setState({
                                visible: false
                            })
                            if (classType === 0) {
                                this.findUserOrder()
                            } else {
                                this.findWorkOrder()
                            }
                        }}>搜索</div>
                    </div>
                </Modal>

            </div>)
    }
}
export default index;