import React, { Component } from 'react'
import { Button, List, TextareaItem, Toast } from 'antd-mobile'
import { req_saveWorkDiary, findMyWorkDiary } from './../../api/index'
import cookie from 'react-cookies';
import './index.css'

class repairs extends Component {
    state = {
        click: false,
        work_desc:'',
        isTrue: 0,
    }

    componentWillMount = () => {
        this.fetch()
    }

    // 查询日志描述
    fetch = async () => {
        const res = await findMyWorkDiary()
        if (res.code === 20000) {
            this.setState({
                work_desc: res.data?res.data.work_desc:'',
                isTrue: res.data ? 1 : 0
            })
        }
    }

    // 提交
    submit = async () => {
        const { work_desc } = this.state

        // 验证
        if (work_desc==='') {
            Toast.offline("请补全上述信息！！！")
            return false;
        }
        Toast.loading('正在提交中...', 0)
        this.setState({
            click: true
        })
        const params = {
            work_desc,
            user_id:cookie.load('data').user_id,
            user_name:cookie.load('data').user_name,
        }
        const res = await req_saveWorkDiary(params)
        if (res.code === 20000) {
            Toast.hide();
            Toast.success(res.message)
            this.setState({
                click: false
            })
            setTimeout(() => {
                this.props.history.push('/home')
            }, 1000);
        } else {
            Toast.hide();
            this.setState({
                click: false
            })
            Toast.fail(res.message)
        }
    }

    render() {
        const { work_desc, isTrue } = this.state
        return (
            <div style={{ height:'100vh',background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List className="listsingle" renderHeader={() => <div><span style={{ color: "red" }}>* </span>日志描述</div>}>
                    <TextareaItem
                        className="my-text"
                        placeholder=""
                        rows={5}
                        count={100}
                        value={work_desc}
                        disabled={isTrue}
                        onChange={(val) => {
                            this.setState({
                                work_desc: val
                            })
                        }}
                    />
                </List>
                {
                    (!isTrue) && <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <Button type="primary" disabled={this.state.click || false} style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} onClick={() => {
                            this.submit()
                        }}>提交</Button>
                    </div>
                }
                
            </div>
        )
    }
}
export default repairs;