import React, { Component } from 'react'
import { List, InputItem, Button, Toast } from 'antd-mobile'
import cookie from 'react-cookies'
import './index.css'
import { createForm } from 'rc-form';
import { findAppUser, updateSysUser } from '../../api/index'


class details extends Component {
    state = {
        data: {},
    }

    componentWillMount = () => {
        this.fetch(cookie.load('data').user_id)
    }

    // 查询用户信息
    fetch = async userId => {
        const res = await findAppUser({ userId })
        if (res.code === 20000) {
            this.setState({
                data: res.data
            })
        }
    }

    // 保存
    handleSubmit = () => {
        this.props.form.validateFields(async (error, value) => {
            if (error) return Toast.info('请输入合理的个人信息')
            if(value.user_name==='' || value.user_account===''){
                Toast.offline("请补全上述信息！！！")
                return false;
            }
            value.user_id=this.state.data.user_id
            const res = await updateSysUser(value)
            if (res.code === 20000) {
                Toast.success(res.message)
                if(this.props.match.params.id!=='0'){
                    this.props.history.push(`/roomInfo/index/${this.props.match.params.id}`)
                }else{
                    this.props.history.push('/home')
                }
            }
        })
    }

    render() {
        const { getFieldProps } = this.props.form;
        const { data } = this.state
        return (
            <div style={{ height:'100vh',background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List>
                    <InputItem disabled={data.user_account} {...getFieldProps('user_account', { initialValue: data.user_account })}>
                    <span style={{ color: "red" }}>* </span>账号
                    </InputItem>

                    <InputItem {...getFieldProps('user_name', { initialValue: data.user_name })}>
                    <span style={{ color: "red" }}>* </span>姓名
                    </InputItem>
                    <InputItem {...getFieldProps('user_nick', { initialValue: data.user_nick })}>
                        &nbsp;&nbsp;昵称
                    </InputItem>
                    <InputItem type="phone" {...getFieldProps('user_phone', { initialValue: data.user_phone })}>
                        &nbsp;&nbsp;手机号
                    </InputItem>
                </List>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button type='primary' style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} onClick={() => this.handleSubmit()}>保存</Button>
                </div>
            </div>
        )
    }
}

export default createForm()(details);