  const IP = {
    // host:'http://192.168.1.115:8871', //苗
    host:'http://114.116.74.150:8871',
    // host:'http://rc.xcu.edu.cn:8871',
    // host:'http://10.5.10.31:8871',
    // Appid:'wx149ef3aca682a2c0',
    Appid: 'wx26b8a637617b81bd',
    // wxredirect:'http://rc.xcu.edu.cn',
    wxredirect:'http://wenjuan.sastc.cn',
}
export default IP