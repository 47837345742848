import React, { Component } from 'react'
import { List, InputItem,Toast } from 'antd-mobile'
import { req_findAppPatrolTaskDetailsByDetailsId } from './../../api/index'
import './details.css'


class details extends Component {
    state = {
        data:[]
    }

    componentWillMount = () => {
        this.findData(this.props.match.params.id)
    }

    findData=async(detailsId)=>{
        const res = await req_findAppPatrolTaskDetailsByDetailsId({detailsId})
        if(res.code === 20000){
            this.setState({
                data:res.data
            })
        }else{
            Toast.fail(res.message)
        }

    }

    render() {
        const { data } = this.state
        return (
            <div style={{ height:'100vh',background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List>
                    {
                        data.map(val=>{
                            return (
                                <InputItem
                                    disabled
                                    value={val.state===2?'进行中':'已完成'}
                                    className="poll-inputItem"
                            >{val.room_name}</InputItem>
                            )
                        })
                    }
                </List>
            </div>
        )
    }
}
export default details;