import React, { Component } from 'react'
import { Toast, SegmentedControl, Modal, SearchBar } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';
import { req_findAppPatrolTaskDetails, req_findAppPatrolRecord } from '../../api/index'
import moment from 'moment'
import './index.css'
import img7 from './../../image/07.png'

class index extends Component {

    state = {
        orderState: '',
        repairsDesc: '',
        userOrderData: [],
        visible: false,
        classType: 0,
        taskData: [
            {
                value: '',
                label: '全部'
            },
            {
                value: 1,
                label: '进行中'
            },
            {
                value: 2,
                label: '已完成'
            },
            {
                value: 3,
                label: '已结束'
            },
        ],
        recordData: [
            {
                value: '',
                label: '全部'
            },
            {
                value: 1,
                label: '正常'
            },
            {
                value: 2,
                label: '异常'
            },
        ],
        state: ''
    }

    componentWillMount = () => {
        // 绑定监听事件
        document.addEventListener('keydown', this.onkeydown);
        this.findTaskDetails();
    }

    // 巡检任务
    findTaskDetails = async () => {
        const { state } = this.state;
        const res = await req_findAppPatrolTaskDetails({ state })
        if (res.code === 20000) {
            this.setState({
                userOrderData: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 巡检记录
    findTaskRecord = async () => {
        const { state } = this.state;
        const res = await req_findAppPatrolRecord({ state })
        if (res.code === 20000) {
            this.setState({
                userOrderData: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 选择
    tabSub = (state) => {
        this.setState({
            orderState: state,
            state,
        }, () => {
            if (this.state.classType === 0) {
                this.findTaskDetails()
            } else {
                this.findTaskRecord()
            }
        })
    }

    // 回车触发
    onkeydown = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                visible: false
            })
            this.findTaskDetails()
        }
    }

    render() {
        const { orderState, userOrderData, visible, repairsDesc, classType, taskData, recordData, clickId } = this.state
        return (
            <div className='home'>
                <div style={{ padding: '12px 16px 4px',display:'flex',justifyContent: 'center' }}>
                    <SegmentedControl className="polling-segment" values={['巡检任务', '巡检记录']} selectedIndex={classType} onChange={(val) => {
                        if (val.nativeEvent.selectedSegmentIndex === 0) {
                            this.setState({
                                orderState: '',
                                state: '',
                                classType: val.nativeEvent.selectedSegmentIndex
                            },()=>{
                                this.findTaskDetails()
                            })
                        } else {
                            this.setState({
                                orderState: '',
                                state: '',
                                classType: val.nativeEvent.selectedSegmentIndex
                            },()=>{
                                this.findTaskRecord()
                            })
                        }
                        
                    }} />
                </div>
                <div className="tables" style={{padding:'0px 11px'}}>
                    <div className="poll-tables-item">
                        {
                            classType === 0 && taskData.map((val, key) => {
                                return <div key={key} className={orderState === val.value ? 'pollTabClick' : ''} onClick={() => {
                                    this.tabSub(val.value)
                                }}>
                                    <span className={orderState === val.value ? 'pollBot' : ''}>{val.label}</span>
                                </div>
                            })
                        }
                        {
                            classType === 1 && recordData.map((val, key) => {
                                return <div key={key} className={orderState === val.value ? 'pollTabClick' : ''} onClick={() => {
                                    this.tabSub(val.value)
                                }}>
                                     <span className={orderState === val.value ? 'pollBot' : ''}>{val.label}</span>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="poll-details">
                    {/* 巡检任务 */}
                    {
                        (classType === 0 && userOrderData) && userOrderData.map((val, key) => {
                            return (
                                <div className="poll-item" key={key} onClick={() => {
                                    this.props.history.push(`/polling/details/${val.details_id}`)
                                }}>
                                    <div className="poll-head">
                                        <div className="poll-img">
                                            <img src={img7} alt='' />
                                        </div>
                                        <div className="poll-title-cont">
                                            <div>
                                                <div className="poll-title">{val.room_name.length > 14 ? val.room_name.substr(0, 14) + '...' : val.room_name}</div>
                                                <div className="poll-name">巡检人：{val.user_name}</div>
                                                <div className="poll-taskTime">
                                                    起始时间：{moment(val.start_time).format('YYYY-MM-DD')} - {moment(val.end_time).format('YYYY-MM-DD')}
                                                </div>
                                                {/* <div className="poll-taskTime">
                                                    巡检结束时间：{moment(val.end_time).format('YYYY-MM-DD')}
                                                </div> */}
                                            </div>
                                            {/* {
                                                val.state === 2 ? (<div className="poll-normal">
                                                    完
                                                </div>) : (<div className="poll-abnormal">
                                                    进
                                                </div>)} */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/* 巡检记录 */}
                    {
                        (classType === 1 && userOrderData) && userOrderData.map((val, key) => {
                            return (<div className="poll-item" key={key}>
                                <div className="poll-head">
                                    <div className="poll-img">
                                        <img src={img7} alt='' />
                                    </div>
                                    <div className="poll-title-cont">
                                        <div>
                                            <div className="poll-title">{val.room_name.length > 14 ? val.room_name.substr(0, 14) + '...' : val.room_name}</div>
                                            <div className="poll-name">巡检人：{val.user_name}</div>
                                            <div className="poll-time">
                                                巡检于{moment(val.patrol_time).format('YYYY-MM-DD HH:mm')}
                                            </div>
                                        </div>
                                        {
                                            val.state === 1 ? (<div className="poll-normal">
                                                正
                                            </div>) : (<div className="poll-abnormal">
                                                异
                                            </div>)}
                                    </div>

                                </div>
                                <div className="poll-desc" style={{marginBottom:'16px'}}>
                                    巡检内容：
                            {

                                    }
                                    {clickId === val.record_id && String(val.work_desc).length > 46 ? val.work_desc : String(val.work_desc).substr(0, 46)}

                                    {
                                        clickId === val.record_id + '...'
                                    }

                                    {
                                        (clickId !== val.record_id && String(val.work_desc).length > 46) && (<span onClick={() => {
                                            this.setState({
                                                clickId: val.record_id
                                            })
                                        }}>展开</span>)
                                    }

                                    {
                                        clickId === val.record_id ?(<span onClick={() => {
                                            this.setState({
                                                clickId: ''
                                            })
                                        }}>收缩</span>):('')
                                    }

                                </div>
                            </div>)
                        })
                    }

                </div>
                {
                    userOrderData.length === 0 && <div style={{ textAlign: 'center', paddingTop: '14px', background: 'none' }}>
                        --暂无数据--
                    </div>
                }

                {
                    userOrderData.length !== 0 && <div style={{ textAlign: 'center', paddingTop: '26px', color: '#a4a9b2', background: 'none' }}>
                        已到底部，没有更多记录啦
                    </div>
                }

                <Modal
                    visible={visible}
                >
                    <div className="search">
                        <SearchBar placeholder={classType === 0 ? '搜索维修工单描述' : '搜索报修人名字'} value={repairsDesc} onCancel={() => {
                            this.setState({
                                repairsDesc: '',
                                visible: false
                            }, () => {
                                if (classType === 0) {
                                    this.findTaskDetails()
                                } else {
                                    this.findTaskRecord()
                                }
                            })
                        }} onKeyDown={(e) => this.onkeydown(e)} onChange={(val) => {
                            this.setState({
                                repairsDesc: val
                            })
                        }} />
                        <div className="sub-search" onClick={() => {
                            this.setState({
                                visible: false
                            })
                            if (classType === 0) {
                                this.findTaskDetails()
                            } else {
                                this.findTaskRecord()
                            }
                        }}>搜索</div>
                    </div>
                </Modal>

            </div>)
    }
}
export default index;