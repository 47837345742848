import axios from 'axios';
import { Toast } from 'antd-mobile';
import qs from 'qs';
import cookie from 'react-cookies';
import IP from './config';

let baseUrl = IP.host;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 1000000;

// let token = cookie.load('token')?cookie.load('token'):''
const instance = axios.create({
  // 当创建实例的时候配置默认配置
  xsrfCookieName: 'xsrf-token',
  baseURL: baseUrl,
  timeout: 1000000,
  responseType: 'json',
});


instance.interceptors.request.use({
  success(config) {
    return config;
  },
  error: error => {
    return Promise.reject(error);
  },
});


instance.interceptors.response.use(
  function(response) {
    if (response.data.code === 20004) {
      cookie.remove('token');
      cookie.remove('data');
      window.location.href="/";
    }
    if (response.data.code === 20003) {
      Toast.info('权限不足！');
    }

    if (response.data.code === 20001) {
      Toast.info('执行错误');
    }
    return Promise.resolve(response.data);
  },
  err=>{
    Toast.hide();
    Toast.offline('请确认网络畅通,并在连接校园网下访问！', 0);
  },
);

export default {
  get(url, param) {
    return new Promise(resolve => {
      instance({
        method: 'get',
        url,
        headers:{'x-auth-token':cookie.load('token')},
        params: param,
      }).then(res => {
        resolve(res);
      });
    }).catch(() => {});
  },
  post(url, param) {
    return new Promise(resolve => {
      instance({
        method: 'post',
        url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded','x-auth-token':cookie.load('token')  },
        data: qs.stringify(param),
      })
        .then(res => {
          if(res){
            resolve(res);
          }
        })
        .catch(() => {});
    });
  },
  jsonPost(url, param) {
    return new Promise(resolve => {
      instance({
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token':cookie.load('token')
        },
        data: param,
      })
        .then(res => {
          resolve(res);
        })
        .catch(() => {});
    });
  },
};
