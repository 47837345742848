import React, { Component } from 'react'
import { Toast, SearchBar } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';
import { req_findAppWorkDiary, req_findSysUser } from '../../api/index'
import moment from 'moment'
import './index.css'


class index extends Component {

    state = {
        orderState: '',
        repairsDesc: '',
        userOrderData: [],
        visible: false,
        classType: 0,
        userName: '', // 姓名
        userData: [], // 查询到的用户
        workDiaryData: [],
        tabData: [],
        clickId: '',
        userId: '',
        dateTime: '',
    }

    componentWillMount = () => {
        // 绑定监听事件
        document.addEventListener('keydown', this.onkeydown);
        this.findTabs()
        this.findWorkDiary();
    }

    // 处理选项Tab
    findTabs = () => {
        let data = []
        const atData = [
            {
                value: '',
                label: '全部',
                date: ''
            },
            {
                value: 0,
                label: '本月',
                date: moment().format('YYYY-MM')
            }
        ]
        // 处理一年前的月份
        for (var i = 1; i < 12; i++) {
            const newData = {
                value: i,
                label: moment(new Date().setMonth((new Date().getMonth() - i))).format('MM') + '月',
                date: moment(new Date().setMonth((new Date().getMonth() - i))).format('YYYY-MM')
            }
            data.push(newData)
        }
        data = atData.concat(data)
        this.setState({
            tabData: data
        })
    }

    // 查询所有用户
    findUser = async () => {
        const { userName } = this.state
        const res = await req_findSysUser({ userName, pageNum: 1, pageSize: 99999 })
        if (res.code === 20000) {
            this.setState({
                userData: res.data.list
            })
        } else {
            Toast.fail(res.message)
        }
    }


    // 获取用户工单
    findWorkDiary = async () => {
        const { userId, dateTime, userName } = this.state;
        const res = await req_findAppWorkDiary({ userId, dateTime, userName })
        if (res.code === 20000) {
            this.setState({
                workDiaryData: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }
    // 回车触发
    onkeydown = (e) => {
        if (e.keyCode === 13) {
            this.findUser();
        }
    }

    render() {
        const { orderState, userName, tabData, workDiaryData, clickId } = this.state
        return (
            <div className='home'>
                {/* <NavBar
                    mode="light"
                    rightContent={[
                        <Icon key="0" type="search" style={{ marginRight: '16px' }} onClick={() => {
                            this.setState({
                                visible: true
                            })
                        }} />,
                    ]}
                >
                    日志管理
                </NavBar> */}

                <div className="search">
                    <SearchBar placeholder='搜索日志提交人' value={userName} onKeyDown={(e) => this.onkeydown(e)} onChange={(val) => {
                        this.setState({
                            userName: val
                        }, () => {
                            // this.findUser();
                            this.findWorkDiary()
                        })
                    }} onCancel={() => {
                        this.setState({
                            userName: '',
                            visible: false
                        }, () => {
                            this.findWorkDiary()
                        })
                    }} />
                    {/* <div className="sub-search" onClick={() => {
                        this.findWorkDiary()
                        this.setState({
                            visible: false
                        })
                    }}>搜索</div> */}
                </div>

                <div className="tables">
                    <div className="tables-item">
                        {
                            tabData.map((val, key) => {
                                return <div key={key} className={orderState === val.value ? 'tabClick' : ''} onClick={() => {
                                    this.setState({
                                        orderState: val.value,
                                        dateTime: val.date
                                    }, () => {
                                        this.findWorkDiary()
                                    })

                                }}>{val.label}</div>
                            })
                        }
                    </div>
                </div>
                <div className="order-details">
                    {
                        workDiaryData.map(val => {
                            return (
                                <div className="order-item" key={val.record_id} style={{ borderBottom: '1px solid #f3f3f3',marginLeft:'16px',padding: '10px 10px 10px 0px' }}>
                                    <div className="order-view">
                                        <div className="order-title" style={{ paddingBottom: '6px' }}>
                                            {val.user_name}
                                        </div>
                                        <div className="order-date">
                                            提交于{moment(val.create_time).format('YYYY-MM-DD HH:mm')}
                                        </div>
                                        <div className="order-text">

                                            {(clickId === val.record_id && val.work_desc.length > 55) ? val.work_desc : val.work_desc.substr(0, 55)}

                                            {clickId !== val.record_id && val.work_desc.length > 55 && '...'}

                                            {(clickId !== val.record_id && val.work_desc.length > 55) && <span style={{ color: '#00c758', paddingLeft: '26px' }} onClick={() => {
                                                this.setState({
                                                    clickId: val.record_id
                                                })
                                            }}>展开</span>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }



                </div>
                {
                    workDiaryData.length === 0 && <div style={{ textAlign: 'center', paddingTop: '14px', background: 'none' }}>
                        --暂无数据--
                    </div>
                }
                {
                    workDiaryData.length !== 0 && <div style={{ textAlign: 'center', paddingTop: '26px', color: '#a4a9b2', background: 'none' }}>
                        已到底部，没有更多记录啦
                    </div>
                }

                {/* <Modal
                    visible={visible}
                >
                    <div className="search">
                        <SearchBar placeholder='搜索日志提交人' value={userName} onKeyDown={(e) => this.onkeydown(e)} onChange={(val) => {
                            this.setState({
                                userName: val
                            }, () => {
                                this.findUser();
                            })
                        }} onCancel={() => {
                            this.setState({
                                userName: '',
                                visible: false
                            }, () => {
                                this.findWorkDiary()
                            })
                        }} />
                        <div className="sub-search" onClick={() => {
                            this.findWorkDiary()
                            this.setState({
                                visible: false
                            })
                        }}>搜索</div>
                    </div>
                    {
                        userData.map(val => {
                            return <div key={val.user_id} className="modal-item" onClick={() => {
                                this.setState({
                                    visible: false,
                                    userId: val.user_id,
                                    userName: val.user_name
                                }, () => {
                                    this.findWorkDiary()
                                })
                            }}>
                                <div>工号：{val.user_account}</div>
                                <div>姓名：{val.user_name}</div>
                            </div>
                        })
                    }
                </Modal> */}

            </div>)
    }
}
export default index;