import React, { Component } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom'
import RouteMap from './route/index'

class App extends Component {
  state = {
    state:false,
  }

  componentDidMount = () => {
    this.setState({
      state:true
    })
  }

  render() {
    const { state } = this.state
    return (
      <BrowserRouter>
        {state && <RouteMap />}
      </BrowserRouter>
    )
  }
}

export default App;
