import React, { Component } from 'react'
import { PickerView, Button } from 'antd-mobile'
import './index.css'

class repairs extends Component {
    state = {
        seasons: [{
            value: '1',
            label: '填单报修'
        }, {
            value: '2',
            label: '电话报修'
        }],
        value:['1'],
    }

    componentWillMount = () => {

    }

    render() {
        const { seasons, value } = this.state
        return (
            <div className="home">
                <div className="repairs-pick">
                    <PickerView
                        onScrollChange={(val)=>{
                            this.setState({
                                value:val
                            })
                        }}
                        data={seasons}
                        cascade={false}
                    />
                </div>
                <div  style={{display:'flex',justifyContent:'center'}}>
                    <Button type="primary" style={{width:'96%',background:'#2bc17b',border:'none'}} onClick={()=>{
                        const num=value.join(',')
                        if(num==='1'){
                            this.props.history.push({pathname:'/repairs/single'})
                        }else{
                            this.props.history.push({pathname:'/repairs/phone'})
                        }
                    }}>确认</Button>
                </div>
            </div>
        )
    }
}
export default repairs;