/**
 * 账号登录
 */

import React, { Component } from 'react';
import { Toast, InputItem, Modal, List, Button } from 'antd-mobile'
import { req_userLogin, req_getWXuserInfo } from './../../api/index'
import cookie from 'react-cookies';
import IP from './../../config/config'
import getQueryString from './../../untils/sha1'
import './index.css'
import img11 from './../../image/11.png'

class Login extends Component {
    state = {
        state: false,
        userAccount: '',
        isShow: false,
        visible:false,
        data:{}
    }

    componentDidMount = () => {
        Toast.loading('加载中...',0)
        this.getCode();
    }

    getCode = () => {
        const state = this.props.location.hash.substr(1)
        if (getQueryString('code')) {
            var code = getQueryString('code')
            req_getWXuserInfo({ code: code }).then(res => {
                Toast.hide()
                if (res.code === 20000) {
                    cookie.save("token", res.data.sessionId)
                    cookie.save("data", res.data)
                    if (res.data.user_account) {
                        // 如果是第一次进入，需要补全个人基本信息
                        // 如果是扫码进来的话，需要补全之后跳转到扫码后的页面
                        // 如果是正常进入，补全信息之后跳转到首页


                        // 扫码登录跳转
                        if (getQueryString('state')) {
                            this.props.history.push(`/roomInfo/index/${getQueryString('state')}`)
                            return false;
                        }
                        this.props.history.push("/home")
                    } else {
                        this.setState({
                            isShow: true
                        })
                    }

                } else {
                    // Toast.fail(res.message)
                    window.location.href = `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${IP.Appid}&redirect_uri=${encodeURIComponent(IP.wxredirect)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
                }
            })
        }
        else {
            window.location.href = `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${IP.Appid}&redirect_uri=${encodeURIComponent(IP.wxredirect)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
        }
    }

    userLogin = async () => {
        if (!this.state.userAccount) {
            Toast.offline('请输入您的工号')
            return false;
        }
        const res = await req_userLogin({ account: this.state.userAccount })
        if (res.code === 20000) {
            if (!res.data) {
                Toast.offline("工号不存在，请重新输入")
                return false;
            } else {
                cookie.save("token", res.data.sessionId)
                cookie.save("data", res.data)
                this.setState({
                    data:res.data
                },()=>{
                    this.setState({
                        visible:true
                    })
                })
            }
        } else {
            Toast.fail(res.message)
        }
    }

    onOK=()=>{
        if (getQueryString('state')) {
            this.props.history.push(`/roomInfo/index/${getQueryString('state')}`)
            return false;
        }
        this.props.history.push("/home")
    }

    render() {
        return (
            <div>
                {
                    this.state.isShow ? (<div className="login-home"><div className="login-item">
                        <div>
                            <InputItem
                                className="my-logo"
                                placeholder="请输入您的工号"
                                onChange={(e) => {
                                    this.setState({
                                        userAccount: e
                                    })
                                }}
                            >
                                <div style={{ backgroundImage: `url(${img11})`, backgroundSize: 'cover', height: '18px', width: '18px' }} />
                            </InputItem>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px' }}>
                            <div className="login-but" onClick={() => {
                                this.userLogin()
                            }}>工号认证</div>
                        </div>
                    </div></div>) : ('')
                }

                <Modal
                    popup
                    visible={this.state.visible}
                    onClose={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    animationType="slide-up"
                    afterClose={() => { 
                        this.setState({
                            visible:false
                        })
                     }}
                >
                    <List renderHeader={() => <div>个人信息</div>} className="popup-list">
                        <div className="login-list-item">工&emsp;&emsp;号：{this.state.data.user_account}</div>
                        <div className="login-list-item">真实姓名：{this.state.data.user_name}</div>
                        <List.Item>
                            <Button type="primary" style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} onClick={()=>{
                                this.onOK()
                            }}>确认</Button>
                        </List.Item>
                    </List>
                </Modal>
            </div>

        )
    }
}

export default Login;
