import React, { Component } from 'react'
import { List, Button, TextareaItem, WingBlank, WhiteSpace, Toast } from 'antd-mobile';
import ktxx from '../../image/kt.png'
import redok from '../../image/red.png'
import redno from '../../image/unred.png'
import blackok from '../../image/black.png'
import blackno from '../../image/unblack.png'
import centerOk from '../../image/centerOk(1).png'
import centerNo from '../../image/centerOk(2).png'
import { req_updateBxOrder } from '../../api/index';


export default class MaintenanceRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            text: '',
            good: false,
            center: false,
            bad: false,
        }
    }

    goodClick = () => {
        if (this.state.good === false) {
            this.setState({
                good: true,
                center: false,
                bad: false,
            })
        } else {
            this.setState({
                good: false,
            })
        }
    }

    centerClick = () => {
        if (this.state.center === false) {
            this.setState({
                center: true,
                good: false,
                bad: false,
            })
        } else {
            this.setState({
                center: false,
            })
        }
    }

    badClick = () => {
        if (this.state.bad === false) {
            this.setState({
                bad: true,
                good: false,
                center: false,

            })
        } else {
            this.setState({
                bad: false,
            })
        }
    }

    submit = async () => {
        if (this.state.good === false &&this.state.center===false && this.state.bad === false) {
            Toast.fail('请选择评价！', 1)
            return ''
        }
        if (this.state.good === true) {
            var cStart = 1
        } else if (this.state.center === true) {
            cStart = 2
        } else {
            cStart = 3
        }
        this.setState({clickAble:true})
        const params = { 
            flag:2,
            orderId: this.props.match.params.id, 
            comment: this.state.text, 
            commentRank: cStart, 
        }
        const res = await req_updateBxOrder(params)
        if (res.code === 20000) {
            Toast.success(res.message, 2);
            window.history.go(-1)
        } else {
            this.setState({clickAble:false})
            Toast.fail(res.message, 1)
        }
    }
    render() {
        return (
            <div style={{ width: "100%", height: "100vh", background: "#F2F2F2" }}>
                {/* <NavBar
                    mode="light"
                    icon={<Icon type="left" />}
                    onLeftClick={() => window.history.go(-1)}
                >评价</NavBar> */}
                <div style={{ display: 'flex', background: "white", paddingLeft: "20px", height: 100, alignItems: 'center' }}>
                    <div style={{ width: 80 }}>
                        <img src={ktxx} style={{ width: '100%', height: '100%' }} alt="卡通形象" />
                    </div>
                    <div style={{ marginRight: 20, lineHeight: 1.5 }}>
                        请您及时对维修人员的维修结果做出评价，十分感谢。
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around', background: "white", paddingBottom: 12 }}>
                    <div onClick={() => {
                        this.goodClick();
                    }}>
                        <img src={this.state.good === false ? blackok : redok} alt="good" width="25px" />
                        <div style={{ paddingTop: 5 }}>满意</div>
                    </div>
                    <div onClick={() => {
                        this.centerClick();
                    }}>
                        <img src={this.state.center===false?centerNo:centerOk} alt="middle" width="25px" />
                        <div style={{ paddingTop: 5 }}>中等</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} onClick={() => {
                        this.badClick();
                    }}>
                        <img src={this.state.bad===false?blackno:redno} alt="bad" width="25px" />
                        <div style={{ paddingTop: 5 }}>不满意</div>
                    </div>
                </div>
                <WhiteSpace size="xl" />
                <WhiteSpace size="md" />
                <WingBlank size="lg">
                    <List>
                        <TextareaItem
                            rows={6}
                            count={100}
                            placeholder="请您评价..."
                            onBlur={(e) => {
                                this.setState({
                                    text: e
                                })
                            }}
                        />
                    </List>
                </WingBlank>
                <WingBlank size="lg">
                    <Button style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} type="primary" onClick={this.submit} disabled={this.state.clickAble}>提交</Button>
                </WingBlank>
            </div>
        )
    }
}
