import http from '../config/http';
import https from '../config/https';
import IP from './../config/config'

// 用户登录
export const req_userLogin = (params) => {
    return http.get('/sysuser/checkTeacherAccount', params)
}

export const req_getWXuserInfo = (params) => {
    return https.post('/syslogin/getWXuserInfo', params)
}

// 查询轮播图信息
export const req_findSlideshow = (params) => {
    return http.get('/sysslideshow/findSlideshow', params)
}

// 查询用户拥有的模块信息
export const req_findModuleByRoleId = (params) => {
    return http.get('/repairmodule/findModuleByRoleId', params)
}

// 手机端填单报修
export const req_saveRepairOrder = (params) => {
    return http.jsonPost('/repairorderapp/saveRepairOrder', params)
}

// 手机端电话报修
export const req_savePhoneRepairOrder = (params) => {
    return http.jsonPost('/repairorderapp/savePhoneRepairOrder', params)
}

// 查询楼宇
export const req_findRepairArea = (params) => {
    return http.get('/repairarea/findRepairArea', params)
}

// 查询教室
export const req_findRepairByRoom = (params) => {
    return http.get('/repairarea/findRepairByRoom', params)
}

// 根据报修区域id查询对应的工单类型信息
export const req_findOrderTypeByAreaIds = (params) => {
    return http.get('/ordertype/findOrderTypeByAreaIds', params)
}

// 上传报修图片
export const req_uploadRepairImage = () => IP.host+'/repairorderapp/uploadRepairImage'


// 根据楼宇id和工单类型id查询对应维修人员信息
export const req_findRepairUser = (params) => {
    return http.get('/repairorderapp/findRepairUser', params)
}

// 手机端用户查询报修工单
export const req_findBxOrder = (params) => {
    return http.get('/repairorderapp/findBxOrder', params)
}

// 手机端用户操作报修工单
export const req_updateBxOrder = (params) => {
    return http.post('/repairorderapp/updateBxOrder', params)
}

// 根据工单id查询工单详情
export const req_findOrderById = (params) => {
    return http.get('/repairorderapp/findOrderById', params)
}

// 手机端查询维修工单
export const req_findWorkOrder = (params) => {
    return http.get('/repairorderapp/findWorkOrder', params)
}

// 手机端维修工维修工单操作
export const req_updateWorkOrder = (params) => {
    return http.post('/repairorderapp/updateWorkOrder', params)
}

// 保存工作日志记录信息
export const req_saveWorkDiary = (params) => {
    return http.jsonPost('/workdiary/saveWorkDiary', params)
}

// pc端查询用户信息
export const req_findSysUser = (params) => {
    return http.get('/sysuser/findSysUser', params)
}

// 手机端查询工作日志记录
export const req_findAppWorkDiary = (params) => {
    return http.get('/workdiary/findAppWorkDiary', params)
}

// 根据区域id查询区域信息
export const req_findRepairAreaById = (params) => {
    return http.get('/repairarea/findRepairAreaById', params)
}

// 手机端用户提交预约
export const req_submitLabRecord = (params) => {
    return http.jsonPost('/labrecord/submitLabRecord', params)
}

// 手机端查询实验室使用记录
export const req_findAppLabRecord = (params) => {
    return http.get('/labrecord/findAppLabRecord', params)
}

// 根据记录id查询记录详情
export const req_findAppLabRecordById = (params) => {
    return http.get('/labrecord/findAppLabRecordById', params)
}

// 提交巡检记录
export const req_submitPatrolRecord = (params) => {
    return http.jsonPost('/patrolrecord/submitPatrolRecord', params)
}

// 手机端查询我的巡检任务
export const req_findAppPatrolTaskDetails = (params) => {
    return http.get('/patroltaskdetails/findAppPatrolTaskDetails', params)
}

// 手机端查询巡检记录
export const req_findAppPatrolRecord = (params) => {
    return http.get('/patrolrecord/findAppPatrolRecord', params)
}

// 手机端根据任务细则id查询任务详情
export const req_findAppPatrolTaskDetailsByDetailsId = (params) => {
    return http.get('/patroltaskdetails/findAppPatrolTaskDetailsByDetailsId', params)
}

// 查询是否有巡检权限
export const req_findAuthority = (params) => {
    return http.get('/patroltaskdetails/findAuthority', params)
}

// 查询是否显示提交按钮
export const req_findIsSubmit = (params) => {
    return http.get('/patroltaskdetails/findIsSubmit', params)
}

// 查询登录用户是否拥有维修权限
export const req_findWorkQuanxian = (params) => {
    return http.get('/sysuser/findWorkQuanxian', params)
}



/******************基本信息**********************/
/**
 * 查询用户信息
 */
export const findAppUser = params => http.get('/sysuser/findAppUser', params)

/**
 * 保存用户信息
 */
export const updateSysUser = params => http.jsonPost('/sysuser/updateSysUser', params)

/***************填写日志描述********************/
/**
 * 查询填写过的日志描述
 */
export const findMyWorkDiary = params => http.get('/workdiary/findMyWorkDiary', params)