import React, { Component } from 'react'
import { Button, List, DatePicker, Toast, InputItem, TextareaItem, Picker } from 'antd-mobile'
import { req_submitLabRecord } from './../../api/index'
import moment from 'moment'

class repairs extends Component {

    state = {
        click: false,
        hourData: [],
        date: '',
        dateString: '',
        hour: [],
        teacher_jianyi: '',
        start_time: '',
        end_time: '',
        teacher_kecheng: '',
        stu_num: '',
        shiyan_content: '',
        zuanye: '',
        jieci: '',
        stu_week: [],
        banji: '',
        startMinDate: new Date(Date.now()),
        startMaxDate: '',
        endMinDate: new Date(Date.now()),
        endMaxDate: '',
        weekData: [
            {
                label: '周一',
                value: '周一'
            },
            {
                label: '周二',
                value: '周二'
            },
            {
                label: '周三',
                value: '周三'
            },
            {
                label: '周四',
                value: '周四'
            },
            {
                label: '周五',
                value: '周五'
            },
            {
                label: '周六',
                value: '周六'
            },
            {
                label: '周日',
                value: '周日'
            },
        ]
    }

    componentWillMount = () => {
        let week = moment().day()
        console.log(week)
        let stu_week = []
        switch (week) {
            case 1:
                stu_week = ['周一']
                break;
            case 2:
                stu_week = ['周二']
                break;
            case 3:
                stu_week = ['周三']
                break;
            case 4:
                stu_week = ['周四']
                break;
            case 5:
                stu_week = ['周五']
                break;
            case 6:
                stu_week = ['周六']
                break;
            default:
                stu_week = ['周日']
        }
        this.setState({
            stu_week
        })
        this.findHour();
    }

    // 循环时间
    time = (hour) => {
        const data = []
        for (var i = hour; i <= 24; i++) {
            const item = {
                label: i < 10 ? '0' + i + ':00' : String(i) + ':00',
                value: i < 10 ? '0' + i : i,
                children: []
            }
            data.push(item)
        }
        return data;
    }

    // 处理时间
    findHour = () => {
        // 处理第一级
        const data = this.time(1)
        const hourData = data.map(val => {
            const item = { ...val }
            // 处理子级
            item.children = this.time(Number(val.value) + 1)
            return item
        })
        this.setState({
            hourData
        })
    }

    // 提交
    submit = async () => {
        const { teacher_jianyi, start_time, end_time, teacher_kecheng, stu_num, shiyan_content, zuanye, jieci, stu_week, banji } = this.state
        // 验证
        if (start_time === '' || end_time === '' || teacher_kecheng === '' || stu_num === '' || zuanye === '' || jieci === '' || stu_week === '' || banji === '') {
            Toast.offline("请补全上述信息！！！")
            return false;
        }
        Toast.loading('正在提交中...', 0)
        this.setState({
            click: true
        })
        const params = {
            room_id: this.props.match.params.id,
            start_time,
            end_time,
            teacher_kecheng,
            stu_num,
            zuanye,
            jieci,
            stu_week: stu_week.join(','),
            banji,
            shiyan_content,
            teacher_jianyi
        }
        const res = await req_submitLabRecord(params)
        if (res.code === 20000) {
            Toast.hide();
            Toast.success(res.message)
            this.setState({
                click: false
            })
            setTimeout(() => {
                window.history.go(-1)
            }, 1000);
        } else {
            Toast.hide();
            this.setState({
                click: false
            })
            Toast.fail(res.message)
        }
    }

    // 展示
    show = () => {
        const { stu_week } = this.state
        return stu_week.length ? stu_week.join('-') : '请选择'
    }

    // 处理两者时间段
    newDateTime = (flag) => {
        const { start_time, end_time } = this.state
        // 为了方便处理使用flag
        if (flag === 1) {
            const endMinDate = start_time
            const endMaxDate = new Date(moment(start_time).format('YYYY-MM-DD 23:59:59'))
            this.setState({
                endMinDate,
                endMaxDate
            })
        }
        if (flag === 2) {
            const startMinDate = new Date(moment(end_time).format('YYYY-MM-DD 00:00:00'))
            // const startMaxDate=end_time
            this.setState({
                startMinDate,
                // startMaxDate
            })
        }

    }

    render() {
        const { teacher_jianyi, weekData, start_time, end_time, teacher_kecheng, stu_num, shiyan_content, zuanye, jieci, stu_week, banji, startMinDate, startMaxDate, endMinDate, endMaxDate } = this.state

        return (
            <div style={{ background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List>
                    <DatePicker
                        mode="datetime"
                        format='YYYY-MM-DD HH:mm:ss'
                        minDate={startMinDate}
                        maxDate={startMaxDate}
                        value={start_time}
                        onChange={date => {
                            this.setState({
                                start_time: date
                            }, () => {
                                this.newDateTime(1)
                            })
                        }}
                    >
                        <List.Item arrow="horizontal"><span style={{ color: "red" }}>* </span>使用开始时间</List.Item>
                    </DatePicker>
                    <DatePicker
                        mode="datetime"
                        format='YYYY-MM-DD HH:mm:ss'
                        minDate={endMinDate}
                        maxDate={endMaxDate}
                        value={end_time}
                        onChange={date => {
                            this.setState({
                                end_time: date,
                            }, () => {
                                this.newDateTime(2)
                            })
                        }}
                    >
                        <List.Item arrow="horizontal"><span style={{ color: "red" }}>* </span>使用结束时间</List.Item>
                    </DatePicker>


                    <InputItem
                        placeholder="请输入上课专业"
                        value={zuanye}
                        onChange={(val) => {
                            this.setState({
                                zuanye: val
                            })
                        }}
                    ><span style={{ color: "red" }}>* </span>上课专业</InputItem>

                    <InputItem
                        placeholder="请输入课程名称"
                        value={teacher_kecheng}
                        onChange={(val) => {
                            this.setState({
                                teacher_kecheng: val
                            })
                        }}
                    ><span style={{ color: "red" }}>* </span>课程名称</InputItem>

                    <InputItem
                        placeholder="请输入班级"
                        value={banji}
                        onChange={(val) => {
                            this.setState({
                                banji: val
                            })
                        }}
                    ><span style={{ color: "red" }}>* </span>班&emsp;&emsp;级</InputItem>

                    <Picker
                        data={weekData}
                        value={stu_week}
                        cols={1}
                        onChange={(val) => {
                            this.setState({
                                stu_week: val
                            })
                        }}
                    >
                        <List.Item arrow="horizontal" className="order-picker">
                            <span>
                                <span style={{ color: "red" }}>* </span>星&emsp;&emsp;期
                            </span>
                            <span style={{ color: "#888", fontSize: '14px' }}>
                                {this.show()}
                            </span>
                        </List.Item>
                    </Picker>

                    <InputItem
                        placeholder="请输入上课节次"
                        value={jieci}
                        onChange={(val) => {
                            this.setState({
                                jieci: val
                            })
                        }}
                    ><span style={{ color: "red" }}>* </span>上课节次</InputItem>



                    <InputItem
                        placeholder="请输入上课人数"
                        type="number"
                        value={stu_num}
                        onChange={(val) => {
                            this.setState({
                                stu_num: val
                            })
                        }}
                    ><span style={{ color: "red" }}>* </span>上课人数</InputItem>


                    <List renderHeader={() => <div style={{ paddingLeft: '8px' }}>实验内容</div>} className="listsingle">
                        <TextareaItem
                            className="my-text"
                            placeholder=""
                            rows={5}
                            count={100}
                            value={shiyan_content}
                            onChange={(val) => {
                                this.setState({
                                    shiyan_content: val
                                })
                            }}
                        />
                    </List>

                    {/* <Picker
                        data={hourData}
                        value={hour}
                        cols={2}
                        onChange={(val) => {
                            this.setState({
                                hour: val
                            })
                        }}
                    >
                        <List.Item arrow="horizontal" className="order-picker">
                            <span>
                                <span style={{ color: "red" }}>* </span>使用时间
                            </span>
                            <span style={{color:"#888",fontSize:'14px'}}>
                                {this.show()}
                            </span>
                        </List.Item>
                    </Picker> */}
                    <List renderHeader={() => <div style={{ paddingLeft: '8px' }}>使用建议</div>} className="listsingle">
                        <TextareaItem
                            className="my-text"
                            placeholder=""
                            rows={5}
                            count={100}
                            value={teacher_jianyi}
                            onChange={(val) => {
                                this.setState({
                                    teacher_jianyi: val
                                })
                            }}
                        />
                    </List>
                </List>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button type="primary" disabled={this.state.click || false} style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} onClick={() => {
                        this.submit()
                    }}>提交</Button>
                </div>
            </div>
        )
    }
}
export default repairs;