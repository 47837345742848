import React, { Component } from 'react'
import { List, InputItem, TextareaItem, Toast, ImagePicker } from 'antd-mobile'
import { req_findOrderById } from './../../api/index'
import IP from './../../config/config'
import './details.css'


class details extends Component {
    state = {
        click: false,
        areaData: [], // 楼宇组
        roomData: [], // 房间组
        typeData: [], // 工单类型
        room_id: [], // 楼宇ID
        building_id: [], // 楼宇ID
        order_type_id: [], // 工单类型ID
        imgArr: [], // 图片
        bx_phone: '', // 手机号
        repairs_desc: '', // 故障描述
        files: [], // 显示图片
        comment:'',
        comment_rank:'',
        reject_desc:''
    }

    componentWillMount = () => {
        this.findOrder()
    }

    // 查询楼宇
    findOrder = async () => {
        Toast.loading('正在加载中...', 0)
        const res = await req_findOrderById({ orderId: this.props.match.params.id })
        if (res.code === 20000) {
            Toast.hide();
            this.setState({
                building_name: res.data.building_name,
                room_name: res.data.room_name,
                order_type: res.data.order_type,
                bx_phone: res.data.bx_phone,
                repairs_desc: res.data.repairs_desc,
                comment:res.data.comment,
                comment_rank:res.data.comment_rank,
                reject_desc:res.data.reject_desc,
                files: res.data && res.data.repairs_url ? res.data.repairs_url.split(',').map((val, key) => {
                    const item = {
                        id: key,
                        url: IP.host + val
                    }
                    return item
                }) : []
            })
        } else {
            Toast.fail(res.message)
        }
    }

    render() {
        const { building_name, room_name, order_type, bx_phone, repairs_desc, files, comment,comment_rank,reject_desc } = this.state
        return (
            <div style={{ height:'100vh',background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List>
                    <InputItem
                        disabled
                        value={building_name}
                    >所属楼宇</InputItem>
                    <InputItem
                        disabled
                        value={room_name}
                    >教室房间</InputItem>
                    <InputItem
                        disabled
                        value={order_type}
                    >工单类型</InputItem>
                    <InputItem
                        disabled
                        type="phone"
                        value={bx_phone}
                    >手机号</InputItem>

         
                    { reject_desc && 
                        <InputItem
                        disabled
                        type="phone"
                        value={reject_desc}
                    >拒接原因</InputItem>
                    }
                    {
                        comment_rank && <InputItem
                        disabled
                        type="phone"
                        value={comment_rank===1?'好评':comment_rank===2?'中评':'差评'}
                    >评价等级</InputItem>
                    }
                    <List className="listsingle" renderHeader={() => '故障描述'}>
                    <TextareaItem
                        disabled
                        rows={5}
                        count={100}
                        value={repairs_desc}
                    />
                    </List>
                    {
                        comment && <List className="listsingle" renderHeader={() => '评价'}>
                        <TextareaItem
                        disabled
                        rows={5}
                        count={100}
                        value={comment}/></List>
                    }
                    {
                        files.length?<ImagePicker
                        files={files}
                        selectable={false}
                        disableDelete
                    />:''
                    }
                    
                </List>
            </div>
        )
    }
}
export default details;