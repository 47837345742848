import React, { Component } from 'react'
import { Button, List, InputItem, TextareaItem, Toast, Picker, ImagePicker } from 'antd-mobile'
import { req_saveRepairOrder, req_findRepairArea, req_findRepairByRoom, req_findOrderTypeByAreaIds, req_uploadRepairImage } from './../../api/index'
import cookie from 'react-cookies';
import './index.css'

class repairs extends Component {
    state = {
        click: false,
        areaData: [], // 楼宇组
        roomData: [], // 房间组
        typeData: [], // 工单类型
        room_id: [], // 楼宇ID
        building_id: [], // 楼宇ID
        order_type_id: [], // 工单类型ID
        imgArr: [], // 图片
        bx_phone: cookie.load('data') && cookie.load('data').user_phone?cookie.load('data').user_phone:'', // 手机号
        repairs_desc: '', // 故障描述
        files: [], // 显示图片
    }

    componentWillMount = () => {
        this.findArea()
    }

    // 查询楼宇
    findArea = async () => {
        Toast.loading('正在加载中...', 0)
        const res = await req_findRepairArea()
        if (res.code === 20000) {
            Toast.hide();
            this.setState({
                areaData: res.data.filter(x => x.parent_id !== 0).map(val => {
                    const item = {}
                    item.value = val.area_id
                    item.label = val.area_name
                    return item
                })
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 查询教室
    findRoom = async (areaId) => {
        Toast.loading('正在加载中...', 0)
        const res = await req_findRepairByRoom({ areaId: areaId.join(','), pageNum: 1, pageSize: 9999 })
        if (res.code === 20000) {
            Toast.hide();
            this.setState({
                roomData: res.data.list.map(val => {
                    const item = {}
                    item.value = val.area_id
                    item.label = val.area_name
                    return item
                })
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 工单类型
    findTrderType = async (areaIds) => {
        Toast.loading('正在加载中...', 0)
        const res = await req_findOrderTypeByAreaIds({ areaIds: areaIds.join(',') })
        if (res.code === 20000) {
            Toast.hide();
            this.setState({
                typeData: res.data.map(val => {
                    const item = {}
                    item.value = val.type_id
                    item.label = val.type_name
                    return item
                })
            })
        } else {
            Toast.fail(res.message)
        }
    }

    // 上传图片
    uploadImage = (files, type, index) => {
        const { imgArr } = this.state;
        Toast.loading('正在上传中...', 0)
        // 添加
        if (type === "add") {
            if (files.length === 0) return false;
            let param = new FormData(); //创建form对象
            param.append('file', files[files.length - 1].file);//通过append向form对象添加数据
            const xhr = new XMLHttpRequest()
            xhr.open('POST', req_uploadRepairImage(), true)
            xhr.setRequestHeader("x-auth-token", cookie.load('token'))
            xhr.send(param)
            xhr.onload = (res) => {
                Toast.hide();
                const data = JSON.parse(res.currentTarget.responseText).data
                this.setState({
                    imgArr: imgArr.concat(data),
                })
            }
            xhr.upload.onprogress = (e) => {
                console.log(e)
            }
            xhr.onerror = (err) => {
                Toast.fail('上传失败');
            }
        }

        // 删除图片
        if (type === "remove") {
            Toast.hide();
            this.setState({
                imgArr: imgArr.filter((x, indexs) => indexs !== index),
            })
        }
        this.setState({
            files,
        });
    }

    // 提交
    submit = async () => {
        const { areaData, roomData, typeData, room_id, building_id, order_type_id, bx_phone, repairs_desc, imgArr } = this.state

        // 验证
        if (building_id.length === 0 || room_id.length === 0 || order_type_id.length === 0 || bx_phone === '' || repairs_desc === '') {
            Toast.offline("请补全上述信息！！！")
            return false;
        }
        Toast.loading('正在提交中...', 0)
        this.setState({
            click: true
        })
        const params = {
            building_id: building_id.join(','),
            building_name: areaData.find(x => String(x.value) === building_id.join(',')).label,
            room_id: room_id.join(','),
            room_name: roomData.find(x => String(x.value) === room_id.join(',')).label,
            order_type_id: order_type_id.join(','),
            order_type: typeData.find(x => String(x.value) === order_type_id.join(',')).label,
            bx_phone,
            repairs_desc,
            repairs_url: imgArr.join(',')
        }
        const res = await req_saveRepairOrder(params)
        if (res.code === 20000) {
            Toast.hide();
            Toast.success(res.message)
            this.setState({
                click: false
            })
            setTimeout(() => {
                this.props.history.push('/home')
            }, 1000);
        } else {
            Toast.hide();
            this.setState({
                click: false
            })
            Toast.fail(res.message)
        }
    }

    render() {
        const { areaData, roomData, building_id, room_id, typeData, order_type_id, bx_phone, repairs_desc, files } = this.state
        return (
            <div style={{ background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List>
                    <Picker data={areaData} value={building_id} cols={1} className="forss" onChange={(val) => {
                        this.setState({
                            building_id: val
                        })
                        this.findRoom(val)
                        this.findTrderType(val)
                    }}>
                        <List.Item arrow="horizontal"><span style={{ color: "red" }}>* </span>所属楼宇</List.Item>
                    </Picker>

                    <Picker data={roomData} value={room_id} cols={1} className="forss" onChange={(val) => {
                        this.setState({
                            room_id: val
                        })
                    }}>
                        <List.Item arrow="horizontal"><span style={{ color: "red" }}>* </span>教室房间</List.Item>
                    </Picker>

                    <Picker data={typeData} value={order_type_id} cols={1} className="forss" onChange={(val) => {
                        this.setState({
                            order_type_id: val
                        })
                    }}>
                        <List.Item arrow="horizontal"><span style={{ color: "red" }}>* </span>工单类型</List.Item>
                    </Picker>
                    <InputItem
                        placeholder="请输入手机号"
                        type="phone"
                        value={bx_phone}
                        onChange={(val) => {
                            this.setState({
                                bx_phone: val
                            })
                        }}
                    ><span style={{ color: "red" }}>* </span>手机号</InputItem>
                    <List className="listsingle" renderHeader={() => <div><span style={{ color: "red" }}>* </span>故障描述</div>}>
                        <TextareaItem
                            className="my-text"
                            placeholder=""
                            rows={5}
                            count={100}
                            value={repairs_desc}
                            onChange={(val) => {
                                this.setState({
                                    repairs_desc: val
                                })
                            }}
                        />
                    </List>
                    <ImagePicker
                        files={files}
                        onChange={(files, type, index) => this.uploadImage(files, type, index)}
                        selectable={files.length < 4}
                    />
                </List>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Button type="primary" disabled={this.state.click || false} style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} onClick={() => {
                        this.submit()
                    }}>提交</Button>
                </div>
            </div>
        )
    }
}
export default repairs;