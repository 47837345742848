import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Login from '../pages/login/index';
import Home from '../pages/home/index';
import RepairsIndex from '../pages/repairs/index';
import RepairsSingle from '../pages/repairs/single';
import RepairsPhone from '../pages/repairs/phone';
import RepairsList from '../pages/repairs/list';
import RepairsDetails from '../pages/repairs/details';
import RepairsEvaluate from '../pages/repairs/evaluate';
import Record from '../pages/record/index';
import RecordAdd from '../pages/record/add';
import My from '../pages/my/index';
import RoomInfo from '../pages/roomInfo/index';
import OrderIndex from '../pages/order/index';
import OrderAdd from '../pages/order/add';
import OrderDetails from '../pages/order/details';
import Polling from '../pages/polling/add';
import PollingIndex from '../pages/polling/index';
import PollingDetails from '../pages/polling/details';

class RouteMap extends Component {
    render() {
        return (
            <div>
                <Route exact path="/" component={Login} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/repairs/index" component={RepairsIndex} />
                <Route exact path="/repairs/single" component={RepairsSingle} />
                <Route exact path="/repairs/phone" component={RepairsPhone} />
                <Route exact path="/repairs/list" component={RepairsList} />
                <Route exact path="/repairs/details/:id" component={RepairsDetails} />
                <Route exact path="/repairs/evaluate/:id" component={RepairsEvaluate} />
                <Route exact path="/record/index" component={Record} />
                <Route exact path="/record/add" component={RecordAdd} />
                <Route exact path="/my/index/:id" component={My} />
                <Route exact path="/roomInfo/index/:id" component={RoomInfo} />
                <Route exact path="/order/index" component={OrderIndex} />
                <Route exact path="/order/add/:id" component={OrderAdd} />
                <Route exact path="/order/details/:id" component={OrderDetails} />
                <Route exact path="/polling/add/:id" component={Polling} />
                <Route exact path="/polling/index" component={PollingIndex} />
                <Route exact path="/polling/details/:id" component={PollingDetails} />
            </div>
        )
    }
}

export default RouteMap