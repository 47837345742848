import React, { Component } from 'react';
import { Toast } from 'antd-mobile'
import { req_findRepairAreaById, req_findAuthority } from './../../api/index'
import './index.css'
import img13 from './../../image/13.png'
import img14 from './../../image/14.png'
import img15 from './../../image/15.png'
import img16 from './../../image/16.png'
import img17 from './../../image/17.png'

export default class componentName extends Component {
    state = {
        data: {},
        showPolling:false,
        overdue:false
    }

    componentWillMount = async() => {
        await this.findAuthority()
        await this.findData();
    }

    // 判断是否有巡检按钮权限
    findAuthority=async()=>{
        const res = await req_findAuthority({roomId:this.props.match.params.id})
        if (res.code === 20000) {
            this.setState({
                showPolling:res.data.quanxian?true:false,
                overdue:res.data.guoqi===0?true:false,
            })
        } else {
            Toast.fail(res.message)
        }
    }

    findData = async () => {
        const res = await req_findRepairAreaById({ areaId: this.props.match.params.id })
        if (res.code === 20000) {
            this.setState({
                data: res.data
            })
        } else {
            Toast.fail(res.message)
        }
    }

    render() {
        const { data, showPolling, overdue } = this.state
        return (
            <div>
                <div className="room-cont">
                    <div className="room-item">
                        <div className="room-img">
                            <img src={img13} alt='' />
                        </div>
                        <div className="room-desc">
                            <div className="room-name">{data.building_name && ''}{data.building_name}{data.area_name}</div>
                        </div>
                    </div>

                    <div className="room-sub">
                        <div className="room-use" onClick={() => {
                            this.props.history.push({ pathname: `/order/add/${this.props.match.params.id}` })
                        }}>
                            <div className="info-img">
                                <img src={img14} alt='' />
                            </div>
                            <div className="info-item">
                                <div className="info-title">使用实验室</div>
                                <div className="info-desc">使用实验室填写信息</div>
                            </div>
                        </div>
                        {
                            showPolling && (<div className="room-polling" onClick={() => {
                                if(overdue) return false
                                this.props.history.push({ pathname: `/polling/add/${this.props.match.params.id}` })
                            }}>
                                <div className="info-img">
                                    <img src={overdue?`${img16}`:`${img15}`} alt='' />
                                </div>
                                <div className="info-item">
                                    <div className={`info-title ${overdue?'clear':''}`}>巡检实验室</div>
                                    <div className={`info-desc ${overdue?'clear':''}`}>巡检实验室设备安全情况</div>
                                </div>
                            </div>)
                        }

                        {
                            overdue && (<div className="info-hint">
                            <img src={img17} alt="" />
                            <span>不在巡检范围，巡检未启用!!!</span>
                        </div>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}
