import React, { Component } from 'react'
import {  List, Toast, InputItem, TextareaItem } from 'antd-mobile'
import { req_findAppLabRecordById } from './../../api/index'
import moment from 'moment'

class repairs extends Component {

    state = {
        click: false,
        hourData: [],
        date: '',
        dateString: '',
        hour: [],
        teacher_jianyi: '',
        start_time: '',
        end_time: '',
        teacher_kecheng: '',
        stu_num: '',
        shiyan_content: '',
        zuanye: '',
        jieci: '',
        stu_week: [],
        banji: '',
        startMinDate: new Date(Date.now()),
        startMaxDate: '',
        endMinDate: new Date(Date.now()),
        endMaxDate: '',
        weekData: [
            {
                label: '周一',
                value: '周一'
            },
            {
                label: '周二',
                value: '周二'
            },
            {
                label: '周三',
                value: '周三'
            },
            {
                label: '周四',
                value: '周四'
            },
            {
                label: '周五',
                value: '周五'
            },
            {
                label: '周六',
                value: '周六'
            },
            {
                label: '周日',
                value: '周日'
            },
        ]
    }

    componentWillMount = () => {
        this.findData(this.props.match.params.id);
    }

    findData=async(recordId)=>{
        const res = await req_findAppLabRecordById({recordId})
        if(res.code === 20000){
            this.setState({
                start_time:moment(res.data.start_time).format('YYYY-MM-DD HH:mm'),
                end_time:moment(res.data.end_time).format('YYYY-MM-DD HH:mm'),
                teacher_kecheng:res.data.teacher_kecheng,
                stu_num:res.data.stu_num,
                zuanye:res.data.zuanye,
                jieci:res.data.jieci,
                stu_week:res.data.stu_week,
                banji:res.data.banji,
                shiyan_content:res.data.shiyan_content,
                teacher_jianyi:res.data.teacher_jianyi
            })
        }else{
            Toast.fail(res.message)
        }
    }

    // 展示
    show = () => {
        const { stu_week } = this.state
        return stu_week.length ? stu_week.join('-') : '请选择'
    }

    render() {
        const { teacher_jianyi, start_time, end_time, teacher_kecheng, stu_num, shiyan_content, zuanye, jieci, stu_week, banji } = this.state

        return (
            <div style={{ background: '#F6F8F7', padding: '15px 16px 10px' }}>
                <List>
                    <InputItem
                        placeholder=""
                        value={start_time}
                        disabled
                    ><span style={{ color: "red" }}>* </span>使用开始时间</InputItem>

                    <InputItem
                        placeholder=""
                        value={end_time}
                        disabled
                    ><span style={{ color: "red" }}>* </span>使用结束时间</InputItem>

                    <InputItem
                        placeholder="请输入上课专业"
                        value={zuanye}
                        disabled
                    ><span style={{ color: "red" }}>* </span>上课专业</InputItem>

                    <InputItem
                        placeholder="请输入课程名称"
                        value={teacher_kecheng}
                        disabled
                    ><span style={{ color: "red" }}>* </span>课程名称</InputItem>

                    <InputItem
                        placeholder="请输入班级"
                        value={banji}
                        disabled
                    ><span style={{ color: "red" }}>* </span>班&emsp;&emsp;级</InputItem>


                    <InputItem
                        placeholder="请输入班级"
                        value={stu_week}
                        disabled
                    ><span style={{ color: "red" }}>* </span>星&emsp;&emsp;期</InputItem>

                    <InputItem
                        placeholder="请输入上课节次"
                        value={jieci}
                        disabled
                    ><span style={{ color: "red" }}>* </span>上课节次</InputItem>



                    <InputItem
                        placeholder="请输入上课人数"
                        type="number"
                        value={stu_num}
                        disabled
                    ><span style={{ color: "red" }}>* </span>上课人数</InputItem>


                    <List renderHeader={() => <div style={{ paddingLeft: '8px' }}>实验内容</div>} className="listsingle">
                        <TextareaItem
                            className="my-text"
                            placeholder=""
                            rows={5}
                            count={100}
                            value={shiyan_content}
                            disabled
                        />
                    </List>
                    <List renderHeader={() => <div style={{ paddingLeft: '8px' }}>使用建议</div>} className="listsingle">
                        <TextareaItem
                            className="my-text"
                            placeholder=""
                            rows={5}
                            count={100}
                            value={teacher_jianyi}
                            disabled
                        />
                    </List>
                </List>
            </div>
        )
    }
}
export default repairs;