import React, { Component } from 'react'
import { Toast, Modal, SearchBar } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';
import { req_findAppLabRecord } from '../../api/index'
import moment from 'moment'
import './index.css'


class index extends Component {

    state = {
        data: [],
        roomName: '',
        visible: false,
        clickId:''
    }

    componentWillMount = () => {
        // 绑定监听事件
        document.addEventListener('keydown', this.onkeydown);
        this.findData();
    }

    findData = async () => {
        const { roomName } = this.state
        const res = await req_findAppLabRecord({ roomName })
        if (res.code === 20000) {
            this.setState({
                data: res.data.map(val=>{
                    const item  = {...val}
                    item.teacher_jianyi=val.teacher_jianyi && val.teacher_jianyi!=='' && val.teacher_jianyi!==null ? val.teacher_jianyi : '无'
                    return item
                })
            })

        } else {
            Toast.fail(res.message)
        }
    }

    // 回车触发
    onkeydown = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                visible: false
            })
            this.findData()
        }
    }

    render() {
        const { data, visible, roomName, clickId } = this.state
        return (
            <div className='home'>
                {/* <NavBar
                    mode="light"
                    rightContent={[
                        <Icon key="0" type="search" style={{ marginRight: '16px' }} onClick={()=>{
                            this.setState({
                                visible:true
                            })
                        }} />,
                    ]}
                >
                    使用记录
                </NavBar> */}

                <div className="search">
                    <SearchBar placeholder='搜索房间名称' value={roomName} onCancel={() => {
                        this.setState({
                            roomName: '',
                            visible: false
                        }, () => {
                            this.findData()
                        })
                    }} onChange={(val) => {
                        this.setState({
                            roomName: val
                        },()=>{
                            this.findData()})
                    }} />
                    {/* <div className="sub-search" onClick={() => {
                        this.findWorkDiary()
                        this.setState({
                            visible: false
                        })
                    }}>搜索</div> */}
                </div>
                {/* <div className="order-details" style={{ margin: '0px' }}>
                    {
                        data && data.map((val, key) => {
                            return (
                                <div className="order-item" key={key} style={{ padding: '14px 0px 14px 30px' }}>
                                    <div className="order-view">
                                        <div className="order-title" style={{ paddingBottom: '6px' }}>
                                            {val.building_name} {val.room_name}
                                        </div>
                                        <div style={{ fontSize: '14px', color: 'rgb(102, 102, 102)' }}>
                                            使用建议：{val.teacher_jianyi}
                                        </div>
                                        <div className="order-desc">
                                            <div className="order-cont">
                                                <div style={{ color: 'rgb(102, 102, 102)' }}>
                                                    使用开始时间：{moment(val.start_time).format('MM月DD日 HH:mm')}
                                                </div>
                                                <div style={{ color: "#666" }}>
                                                    使用结束时间：{moment(val.end_time).format('MM月DD日 HH:mm')}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}


                <div className="order-details">
                    {
                        data.map(val => {
                            return (
                                <div className="order-item" key={val.record_id} style={{ borderBottom: '1px solid #f3f3f3',marginLeft:'16px',padding: '10px 10px 10px 0px' }} onClick={()=>{
                                    this.props.history.push(`/order/details/${val.record_id}`)
                                }}>
                                    <div className="order-view">
                                        <div className="order-title" style={{ paddingBottom: '6px' }}>
                                            {val.building_name} {val.room_name}
                                        </div>
                                        <div className="order-date" style={{width:'210px'}}>
                                            起始时间:{moment(val.start_time).format('YYYY-MM-DD')}  
                                            &nbsp;&nbsp;
                                            {moment(val.start_time).format('HH:mm')}- {moment(val.end_time).format('HH:mm')}
                                        </div>
                                        <div className="order-text">
                                            {(clickId === val.record_id && val.teacher_jianyi.length > 55) ? val.teacher_jianyi : val.teacher_jianyi.substr(0, 55)}

                                            {clickId !== val.record_id && val.teacher_jianyi.length > 55 && '...'}

                                            {(clickId !== val.record_id && val.teacher_jianyi.length > 55) && <span style={{ color: '#00c758', paddingLeft: '26px' }} onClick={() => {
                                                this.setState({
                                                    clickId: val.record_id
                                                })
                                            }}>展开</span>}

                                            {clickId === val.record_id && <span style={{ color: '#00c758', paddingLeft: '26px' }} onClick={() => {
                                                this.setState({
                                                    clickId: ''
                                                })
                                            }}>收缩</span> }

                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }



                </div>
                
                {
                    data.length === 0 && <div style={{ textAlign: 'center', paddingTop: '14px', background: 'none' }}>
                        --暂无数据--
                    </div>
                }

{
                    data.length !== 0 && <div style={{ textAlign: 'center', paddingTop: '26px', color: '#a4a9b2', background: 'none' }}>
                        已到底部，没有更多记录啦
                    </div>
                }

                <Modal
                    visible={visible}
                >
                    <div className="search">
                        <SearchBar placeholder='搜索房间名称' value={roomName} onCancel={() => {
                            this.setState({
                                roomName: '',
                                visible: false
                            }, () => {
                                this.findData()
                            })
                        }} onKeyDown={(e) => this.onkeydown(e)} onChange={(val) => {
                            this.setState({
                                roomName: val
                            })
                        }} />
                        <div className="sub-search" onClick={() => {
                            this.setState({
                                visible: false
                            })
                            this.findData()
                        }}>搜索</div>
                    </div>
                </Modal>

            </div>)
    }
}
export default index;